import { RichText } from 'prismic-reactjs';
import { SliceZone } from '@prismicio/react';
import * as prismic from '@prismicio/client';
import * as prismicH from '@prismicio/helpers';
import Head from 'next/head';
import React from 'react';

import { components } from '../slices';
import { createClient } from '../prismicio';

import { Layout } from '../components/Layout';
import {
  Page,
  PAGE_BACKGROUND_PRISMIC_MAP,
  PAGE_BACKGROUND,
} from '../components/Page';

import { normalizeSlug } from '../helpers/normalize-slug.js';
import {
  findAllDynamicPages,
  isSlugCorrespondingToDynamicPage,
  getDynamicPageKey,
  getDynamicPageDocumentUid,
  generateDynamicPageKeyRegExp,
} from '../helpers/dynamic-page.js';
import { isImage } from '../helpers/image.js';
import { getPageData } from '../helpers/get-data';
import { DEFAULT_LAYOUT, LOCALES } from '../config/index.js';
import { generateLinkResolver } from '../helpers/link-resolver';

const GlobalPage = ({
  menu,
  layout,
  articles,
  locale,
  events,
  page,
  document = null,
}) => {
  const index =
    document && document.type === 'article'
      ? document.data.index
      : page.data.index;

  const title =
    document && document.type === 'article'
      ? prismicH.asText(document.data.title)
      : prismicH.asText(page.data.seoTitle);

  const description = prismicH.asText(page.data.seoDescription);

  const imageUrl =
    document && document.type === 'article' && isImage(document.data.image)
      ? document.data.image.url
      : page.data.seoImage?.url
      ? page.data.seoImage?.url
      : layout.data.seoImage?.url
      ? layout.data.seoImage?.url
      : null;

  const pageBackground =
    PAGE_BACKGROUND_PRISMIC_MAP[page.data.background] || PAGE_BACKGROUND.AUTO;

  let dark = false;
  switch (pageBackground) {
    case PAGE_BACKGROUND_PRISMIC_MAP['Blanc tapisserie']:
      dark = true;
      break;

    case PAGE_BACKGROUND_PRISMIC_MAP['Bleu sombre']:
    case PAGE_BACKGROUND_PRISMIC_MAP['Bleu sombre dégradé']:
    default:
      dark = false;
  }

  const faqSlices = page.data.body.filter(
    (slice) => slice.slice_type === 'faq'
  );

  const structuredDatas = {
    localBusiness: {
      '@context': 'https://schema.org',
      '@type': ['TouristAttraction', 'Museum'],
      image: ['https://www.cite-histoire.com/logo-dark.svg'],
      name: "La Cité de l'Histoire",
      address: {
        '@type': 'PostalAddress',
        streetAddress: '1 parvis de la Défense',
        addressLocality: 'Puteaux',
        addressRegion: 'Île-de-France',
        postalCode: '92400',
        addressCountry: 'Fr',
      },
      url: 'https://www.cite-histoire.com/',
      telephone: '+33147575727',
      /* openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday'],
          opens: '00:00',
          closes: '00:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Tuesday', 'Wednesday'],
          opens: '10:00',
          closes: '20:30',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Thursday', 'Friday'],
          opens: '12:00',
          closes: '22:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Saturday'],
          opens: '10:00',
          closes: '22:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Sunday'],
          opens: '10:00',
          closes: '20:00',
        },
      ], */
      acceptsReservations: 'True',
    },
  };

  if (faqSlices.length > 0) {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [],
    };

    faqSlices.forEach((slice) => {
      slice.items.forEach((item) => {
        structuredData.mainEntity.push({
          '@type': 'Question',
          name: RichText.asText(item.title),
          acceptedAnswer: {
            '@type': 'Answer',
            text: RichText.asText(item.content),
          },
        });
      });
    });

    structuredDatas.faq = structuredData;
  }

  return (
    <Layout menu={menu} layout={layout} dark={dark} locale={locale}>
      <Head>
        <title>{title}</title>

        {!index ? <meta name="robots" content="noindex,nofollow" /> : null}

        <link
          rel="preload"
          as="image"
          href="https://www.cite-histoire.com/backgrounds/white.jpg"
        />

        <link
          rel="canonical"
          href={`https://${process.env.HOST}${normalizeSlug(locale.slug)}`}
        />

        <meta name="description" content={description} />

        <meta key="twitter-title" name="twitter:title" content={title} />
        <meta
          key="twitter-description"
          name="twitter:description"
          content={description}
        />
        <meta key="twitter-image" name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          key="facebook-title"
          prefix="og: http://ogp.me/ns#"
          property="og:title"
          content={title}
        />
        <meta key="facebook-type" property="og:type" content="website" />
        <meta
          key="facebook-description"
          prefix="og: http://ogp.me/ns#"
          property="og:description"
          content={description}
        />
        <meta
          key="facebook-image"
          prefix="og: http://ogp.me/ns#"
          name="image"
          property="og:image"
          content={imageUrl}
        />
        <meta
          key="facebook-image-alt"
          prefix="og: http://ogp.me/ns#"
          property="og:image:alt"
          content={title}
        />
      </Head>

      <Page background={pageBackground}>
        {Object.values(structuredDatas).map((data, index) => {
          return (
            <script
              key={index}
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(data),
              }}
            />
          );
        })}

        <SliceZone
          slices={page.data.body}
          components={components}
          context={{
            articles,
            events,
            page,
            document,
            layout,
          }}
        />
      </Page>
    </Layout>
  );
};

export async function getStaticProps({ params, previewData }) {
  const client = createClient({ previewData });

  let pathPrefix = '';

  if (
    params.slug &&
    LOCALES.find((item) => item.shortCode === params.slug[0])
  ) {
    pathPrefix = params.slug.shift();
  }

  const requestLang = pathPrefix
    ? LOCALES.find((item) => item.shortCode === pathPrefix)
    : DEFAULT_LAYOUT.locale;

  const slug = `/${(params.slug || []).join('/')}`;

  const articles = await client.getAllByType('article', {
    lang: requestLang?.locale,
  });

  const events = await client.getAllByType('event', {
    lang: requestLang?.locale,
  });

  const rest = {};

  try {
    rest.page = await client.getFirst({
      predicates: [
        prismic.predicate.at('document.type', 'page'),
        prismic.predicate.at('my.page.slug', slug),
      ],
      lang: requestLang?.locale,
    });
  } catch (error) {}

  if (!rest.page) {
    const pages = await client.getAllByType('page', {
      lang: requestLang?.locale,
    });

    const dynamicPages = findAllDynamicPages(pages);

    for (const dynamicPage of dynamicPages) {
      if (
        isSlugCorrespondingToDynamicPage({
          page: dynamicPage,
          slug,
        })
      ) {
        const key = getDynamicPageKey(dynamicPage);

        const uid = getDynamicPageDocumentUid({
          page: dynamicPage,
          slug,
        });

        let document = null;

        try {
          document = await client.getByUID(key, uid, {
            lang: requestLang?.locale,
          });
        } catch (error) {}

        if (document) {
          rest.page = dynamicPage;
          rest.document = document;
        }
      }
    }
  }

  if (!rest.page) {
    return {
      notFound: true,
    };
  }

  const currentLocale = LOCALES.find((item) => item.locale === rest.page.lang);

  const menu = await client.getSingle('menu', { lang: rest.page.lang });
  const layout = await client.getSingle('layout', { lang: rest.page.lang });

  return {
    props: {
      menu,
      layout,
      locale: {
        alternate_languages: await getPageData(
          rest.page.id,
          currentLocale?.locale,
          client,
          rest.document ? rest.document : null
        ),
        current: currentLocale,
        slug:
          pathPrefix +
          (rest.document
            ? normalizeSlug(
                rest.page.data.slug.replace(
                  generateDynamicPageKeyRegExp(rest.document.type),
                  rest.document.uid
                )
              )
            : rest.page.data.slug),
      },
      articles: articles.map((article) => {
        return {
          type: article.type,
          id: article.id,
          uid: article.uid,
          lang: currentLocale?.locale,
          data: {
            content: article.data.content,
            image: {
              url: article.data.image.url,
              alt: article.data.image.alt,
            },
            index: article.data.index,
            publishDate: article.data.publishDate,
            supTitle: article.data.supTitle,
            title: article.data.title,
            subTitle: article.data.subTitle,
          },
        };
      }),

      events: events.map((event) => {
        return {
          type: event.type,
          id: event.id,
          uid: event.uid,
          lang: currentLocale?.locale,
          data: {
            content: event.data.content,
            image: {
              url: event.data.image.url,
              alt: event.data.image.alt,
            },
            date: event.data.date,
            title: event.data.title,
          },
        };
      }),

      ...rest,
    },
  };
}

export async function getStaticPaths() {
  const client = createClient();

  const pages = await client.getAllByType('page', { lang: '*' });
  const articles = await client.getAllByType('article', { lang: '*' });

  const linkResolver = generateLinkResolver({
    pages,
  });

  const paths = [
    ...pages
      .map((page) => {
        const locale = LOCALES.find((item) => {
          return item.locale === page.lang;
        });

        const pathPrefix =
          locale?.shortName !== DEFAULT_LAYOUT.locale.shortName &&
          page.data.slug !== locale?.pathPrefix
            ? locale?.pathPrefix
            : '';

        return `${pathPrefix}${page.data.slug}`;
      })
      .filter((path) => {
        return path && !path.includes(':');
      }),

    ...articles.map((article) => {
      return prismicH.asLink(article, linkResolver);
    }),
  ];

  return {
    paths,
    fallback: false,
  };
}

export default GlobalPage;
