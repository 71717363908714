import { PrismicLink } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import classnames from 'classnames';
import React, { useState } from 'react';

import { LOCALES } from '../../config/index.js';

import { Button } from '../Button';
import { Link } from '../Link';

export const Menu = ({ menu, open, closing, toggleMenu, locale }) => {
  const [touchDown, setTouchDown] = useState(false);

  const alternateLocales = LOCALES.filter((alternateLocale) => {
    return locale.current.locale !== alternateLocale.locale;
  });

  return (
    <nav
      className={classnames('menu', {
        'menu--open': open,
        'menu--animate-close': closing,
      })}
    >
      <div className="menu__header">
        <Link className="menu__logo" href="/" onClick={toggleMenu}>
          <img src="/logo.svg" alt="logo" />
        </Link>

        <button
          className={classnames('menu__burger', {
            'menu__burger--touch-down': touchDown,
          })}
          onClick={toggleMenu}
          onTouchStart={() => setTouchDown(true)}
          onTouchMove={() => setTouchDown(false)}
          onTouchEnd={() => setTouchDown(false)}
        >
          <div />
        </button>
      </div>

      <div className="menu__lists">
        <ul>
          {menu.data.menuLinks.map((item, index) => {
            return (
              <li key={index} onClick={toggleMenu}>
                <PrismicLink field={item.link}>
                  {prismicH.asText(item.label)}
                </PrismicLink>
              </li>
            );
          })}
        </ul>

        {alternateLocales.length > 0 ? (
          <ul className="menu__locales">
            {alternateLocales.map((alternateLocale) => {
              const alternatePageSlug = locale.alternate_languages.find(
                (item) => item.lang === alternateLocale.locale
              );

              const href = alternatePageSlug
                ? alternatePageSlug.slug
                : alternateLocale.pathPrefix;

              return (
                <li
                  key={alternateLocale.locale}
                  className={classnames(
                    'lang-switcher__item footer_lang-switcher__item',
                    {
                      'lang-switcher__item--current':
                        locale.current.locale === alternateLocale.locale,
                    }
                  )}
                >
                  <Link
                    hrefLang={alternateLocale.shortCode}
                    href={
                      alternateLocale.locale === locale.current?.locale
                        ? locale.slug
                        : href
                    }
                    tabIndex={-1}
                    onClick={toggleMenu}
                  >
                    {alternateLocale.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}

        <ul className="menu__ctas">
          {menu.data.ctas.map((item) => (
            <li key={prismicH.asText(item.label)}>
              <Button
                field={item.link}
                height="small"
                rightIcon="up-right-arrow"
                onClick={toggleMenu}
              >
                {prismicH.asText(item.label)}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
