import React from 'react';
import classnames from 'classnames';

export const Main = ({ children, flashInfo }) => {
  return (
    <div
      className={classnames('main', {
        'main--flash-info': flashInfo,
      })}
    >
      {children}
    </div>
  );
};
