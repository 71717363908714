import React, { useRef } from 'react';
import classnames from 'classnames';

import { LOCALES } from '../../config/index.js';
import { Link } from '../Link';

export const LocaleSwitch = ({ locale }) => {
  const ref = useRef();

  if (LOCALES.length < 2 || locale.alternate_languages.length === 0) {
    return null;
  }

  return (
    <div ref={ref} className="lang-switcher">
      <ul>
        {LOCALES.map((alternateLocale) => {
          const alternatePageSlug = locale.alternate_languages.find(
            (item) => item.lang === alternateLocale.locale
          );

          const href = alternatePageSlug
            ? alternatePageSlug.slug
            : alternateLocale.pathPrefix;

          return (
            <li
              key={alternateLocale.locale}
              className={classnames(
                'lang-switcher__item footer_lang-switcher__item',
                {
                  'lang-switcher__item--current':
                    locale.current.locale === alternateLocale.locale,
                }
              )}
            >
              <Link
                hrefLang={alternateLocale.shortCode}
                href={
                  alternateLocale.locale === locale.current?.locale
                    ? locale.slug
                    : href
                }
                tabIndex={-1}
              >
                {alternateLocale.shortName}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
