import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import * as prismicH from '@prismicio/helpers';

import { RichText } from 'prismic-reactjs';

import { MaxWidth } from '../MaxWidth';
import { Button } from '../Button';
import { Link } from '../Link';
import { Menu } from '../Menu';
import { LocaleSwitch } from '../LocaleSwitch';

import { isLink } from '../../helpers/is-link.js';
import config from '../../helpers/config.js';

const FlashInfo = ({ layout, show, setShow }) => {
  const handleAccept = () => {
    setShow(false);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (window && document) {
      const handleScroll = () => {
        setScrolled(
          document.scrollingElement && document.scrollingElement.scrollTop > 0
            ? true
            : false
        );
      };

      window.addEventListener('scroll', handleScroll);

      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div
      className={classnames('flash-info', {
        'flash-info--show': show,
        'flash-info--scrolled': scrolled,
      })}
    >
      <div className="flash-info__inner">
        <img
          src="/icons/flash-info.png"
          srcSet="/icons/flash-info.svg"
          alt=""
        />

        <div className="flash-info__content">
          <RichText render={layout.data.flashInfo} />
        </div>
      </div>

      <button onClick={handleAccept} aria-label="Fermer le flash info">
        <img src="/icons/close.png" srcSet="/icons/close.svg" alt="" />
      </button>
    </div>
  );
};

const HeaderNavItem = ({ label, link }) => {
  return (
    <li key={prismicH.asText(label)} className="header__nav__item">
      <Link field={link}>
        <span>{prismicH.asText(label)}</span>
      </Link>
    </li>
  );
};

export const Header = ({
  menu,
  dark,
  locale,
  layout,
  flashInfo,
  setFlashInfo,
}) => {
  const [closing, setClosing] = useState(false);
  const [open, setOpen] = useState(false);
  const [touchDown, setTouchDown] = useState(false);

  const toggleMenu = () => {
    if (!open) {
      setClosing(false);
      setOpen(true);
      document.body.style.overflow = 'hidden';
    } else {
      setClosing(true);
      setOpen(false);
      document.body.style.overflow = 'visible';
    }
  };

  const leftPart = menu.data.menuLinks.slice(
    0,
    Math.round(menu.data.menuLinks.length / 2)
  );

  const rightPart = menu.data.menuLinks.slice(
    Math.round(menu.data.menuLinks.length / 2),
    menu.data.menuLinks.length
  );

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (window && document) {
      const handleScroll = () => {
        setScrolled(
          document.scrollingElement && document.scrollingElement.scrollTop > 0
            ? true
            : false
        );
      };

      window.addEventListener('scroll', handleScroll);

      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <header
        className={classnames('header', {
          'header--scrolled': scrolled,
          'header--dark': dark,
        })}
      >
        <div className="header__gradient"></div>
        {layout.data.flashInfo && prismicH.asText(layout.data.flashInfo) ? (
          <FlashInfo layout={layout} show={flashInfo} setShow={setFlashInfo} />
        ) : null}

        <MaxWidth>
          <div className="header__grid">
            {isLink(menu.data.mobileCtaLink) &&
            prismicH.asText(menu.data.mobileCtaLabel) ? (
              <div className="header__mobile-cta">
                <Button
                  field={menu.data.mobileCtaLink}
                  height="small"
                  rightIcon="up-right-arrow"
                  theme="secondary"
                >
                  {prismicH.asText(menu.data.mobileCtaLabel)}
                </Button>
              </div>
            ) : null}

            <div className="header__part header__part--left">
              <LocaleSwitch locale={locale} />
              <ul className="header__nav">
                {leftPart.map((item) => (
                  <HeaderNavItem key={prismicH.asText(item.label)} {...item} />
                ))}
              </ul>
            </div>

            <Link className="header__logo" href={locale.current.pathPrefix}>
              <img
                className="header__logo__light"
                src="/logo.svg"
                width="60"
                height="60"
                alt={config.siteName}
                draggable={false}
              />
              <img
                className="header__logo__dark"
                src="/logo-dark.svg"
                width="60"
                height="60"
                alt={config.siteName}
                draggable={false}
              />
            </Link>

            <div className="header__part header__part--right">
              <ul className="header__nav">
                {rightPart.map((item) => (
                  <HeaderNavItem key={prismicH.asText(item.label)} {...item} />
                ))}
              </ul>

              <ul className="header__ctas">
                {menu.data.ctas.map((item) => (
                  <li key={prismicH.asText(item.label)}>
                    <Button
                      field={item.link}
                      height="small"
                      rightIcon="up-right-arrow"
                      theme="secondary"
                    >
                      {prismicH.asText(item.label)}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>

            <button
              className={classnames('header__burger', {
                'header__burger--touch-down': touchDown,
              })}
              onClick={toggleMenu}
              onTouchStart={() => setTouchDown(true)}
              onTouchMove={() => setTouchDown(false)}
              onTouchEnd={() => setTouchDown(false)}
            >
              <div />
              <span className="visually-hidden">Open menu</span>
            </button>
          </div>
        </MaxWidth>
      </header>

      <Menu
        menu={menu}
        open={open}
        closing={closing}
        toggleMenu={toggleMenu}
        locale={locale}
      />
    </>
  );
};
